import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/home/HomePage";
import PrivateRoute from "./components/PrivateRoute";
import SignInPage from "./pages/auth/Login";
// import { PrivateRoute } from "./components/PrivateRoute";


function App() {
  return (
    <div className="App">

      <Routes>
        <Route path="/" element={ <PrivateRoute > <HomePage /> </PrivateRoute> } />
        <Route path="/signin" element={<SignInPage />} />
      </Routes>

    </div>
  );
}

export default App;
