import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../config/firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';

// CREATE CONTEXT 
const AuthContext = createContext();

// EXPORT USE AUTH FUNCTION
export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {

    // CREATE CURRENT USER
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);

    // **********************CREATE THE VARIOUS AUTH FUNCTIONS********************** 
    function signup(email, password) {
        return createUserWithEmailAndPassword(auth, email, password);
    }

    function signin(email, password) {

        try {
            return signInWithEmailAndPassword(auth, email, password);

        } catch (err) {
            console.log(err);
        }
    }

    function signout() {
        return auth.signOut();
    }


    // ONSTATE CHANGED 
    useEffect(() => {

        const unsubscribe = auth.onAuthStateChanged((user) => {
            setCurrentUser(user);
            setLoading(false);
        });

        return unsubscribe; // unsubscribe on unmount
    }, []);

    // **********************CREATE THE VARIOUS AUTH FUNCTIONS********************** 



    // CREATE VALUE
    const value = {
        currentUser,
        signup,
        signin,
        signout,
        loading
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
