import { Button } from 'flowbite-react'
import React from 'react'
import { useAuth } from '../../context/AuthContext'

const HomePage = () => {

  const { signout, currentUser, loading } = useAuth();
  const handleLogout = async () => {
    try{
      await signout();
      console.log('Signed Out')
    }catch(err){
      console.log(err)
    }
  }

  console.log(currentUser && currentUser.email);
  console.log('loading', loading);

  return (
    <div>HomePage
      <Button onClick={handleLogout}>Log Out</Button>
    </div>
  )
}

export default HomePage