import { Alert, Button, Card, Spinner, TextInput } from 'flowbite-react';
import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';


const SignIn = () => {

    // CREATE EMAIL AND PASSWORD STATES 
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    // CREATE AUTH CONTEXT
    const { signin } = useAuth();

    const handleSignin = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await signin(email, password);
            console.log('Signed In');
        } catch (err) {
            console.log(err);
            setError(err.message);
            setLoading(false);
        }
    }

    return (
        <div className='min-h-screen w-full bg-gray-100 grid place-items-center'>
            <Card className='shadow-sm bg-white w-5/6 md:w-1/3 '>
                {
                    loading ?
                        <div className="h-[200px] w-full  flex flex-col justify-center items-center">
                            <Spinner className='  ' />
                            <h3 className='text-gray-400'>Loading...</h3>
                        </div>
                        :
                        <form onSubmit={handleSignin} className='flex flex-col gap-3'>
                            <TextInput label="Email" placeholder="Email" type="email"
                                onChange={(e) => setEmail(e.target.value)} value={email}
                            />
                            <TextInput label="Password" placeholder="Password" type="password"
                                onChange={(e) => setPassword(e.target.value)} value={password} />

                            {
                                (error !== '') ? <Alert color={'failure'} variant='success' className='text-center'>{error}</Alert> : ''
                            }

                            <Button className='w-full bg-blue-600 hover:bg-blue-500' type="submit" disabled={loading}>Sign In</Button>
                        </form>
                }

            </Card>
        </div>
    )










}

export default SignIn